.custom-width-nivekino {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

img.img-width {
  width: 163px;
  height: 52px;
  margin-bottom: 40px;
}

img.img-width-2 {
  width: 163px;
  height: 33px;
  margin-bottom: 40px;
}

.sello-container {
  position: absolute;
  right: 13rem;
  bottom: 4.7rem;
  z-index: 10;
  width: 106px;
  height: 100px;
}

.img-sello {
  cursor: pointer;
  width: 100px;
  height: 100px;
  margin-left: 0 !important;
}

@media only screen and (max-width: 768px) {
  .sello-container {
    position: relative;
    right: auto;
    bottom: auto;
    z-index: 10;
    width: 125px;
    height: 125px;
  }

  .img-sello {
    width: 125px;
    height: 125px;
    margin-left: 0 !important;
  }
}
