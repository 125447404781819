@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Source Sans Pro;
    font-weight: 400;
    src: url(../fonts/SourceSansPro-Regular.ttf) format("truetype");
  }
  @font-face {
    font-family: Source Sans Pro;
    font-weight: 600;
    src: url(../fonts/SourceSansPro-SemiBold.ttf) format("truetype");
  }
  @font-face {
    font-family: Source Sans Pro;
    font-weight: 700;
    src: url(../fonts/SourceSansPro-Bold.ttf) format("truetype");
  }

  html {
    scroll-behavior: smooth;
  }

  .prose img {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
